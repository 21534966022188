.list{
  padding-left: 20px;

  .list-item{
    color: $dark-blue;
    display: block;
    border-bottom: solid 1px #dedfec;
    padding-left: 10px;
    position: relative;
    text-align: left;

    a {
      text-decoration: none;
      padding-top: 11px;
      padding-bottom: 6px;
      display: block;
    }

    p {
      display: inline-block;
      margin: 0;
    }

    .list-title{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: calc(100% - 70px)
    }

    svg {
      margin-left: 11px;
      margin-right: 10px;
    }

    svg:first-child {
      margin-left: 0;
    }

    .tools {
      right: 0px;
      top: 0px;
      position: absolute;

      a {
        font-size: 16px;
      }

    }

  }

  .list-item:hover{
    background-color: #dedfec;
  }

  .list-item.selected {
    height:auto;
    p {
      font-weight: bold
    }

    .list-title{
      white-space: initial;
      text-overflow: initial;
      width: calc(100% - 70px)
    }
  }

  .list-item.minus-10, .list-item.selected.minus-10  {
    .list-title{
      width: calc(100% - 10px)
    }
  }

  .list-item.selected:after {
    position: absolute;
    content: "";
    height: 100%;
    bottom: 0;
    top: 0;
    left: -20px;
    right: 0;
    width: 8px;
    border-radius: 0 4px 4px 0;
    background: $tonic-blue;
  }

}

.list.dark {

  .list-item{
    color: $white;
    border-bottom: solid 1px $disabled;

    svg, p {
      color: $white;
    }

  }

  .list-item:hover{
    background-color: $disabled;
  }

}
