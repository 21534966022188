.page-login{
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color: $dark-blue;

  @keyframes imgLogin {
    0%   {margin-bottom: -80px;}
    100% {margin-bottom: 30px;}
  }

  @keyframes imgLoginOpen {
    0%   {opacity: 1;}
    100% {opacity: 0;}
  }

  @keyframes formLogin {
    0%   {margin-top: -100px; opacity:0}
    100% {margin-top: 30px; opacity:1}
  }

  @keyframes formLoginOpen {
    0%   {opacity: 1;}
    100% {opacity: 0;}
  }

  @keyframes lineLogin {
    0%   {width: 0;}
    100% {width: 100%;}
  }

  @keyframes lineLoginOpen {
    0%   {height: 2px;top:50%;margin-top: -1px;}
    100% {height: 100%;top:0;margin-top: 0;}
  }

  @keyframes lineLoginClose {
    0% {height: 100%;top:0;margin-top: 0;}
    100%   {height: 2px;top:50%;margin-top: -1px;}
  }

  @keyframes opacityIn {
    0%   {opacity: 0;}
    100% {opacity: 1;}
  }

  label{
    color:$white;
    display:none;
  }

  .header-login{
    position:absolute;
    display: flex;
    align-items:center;
    justify-content:flex-end;
    flex-direction: column;
    top: 0;
    bottom: 50%;
    left: 0;
    right: 0;
    text-align: center;
  }

  .form-login h1 {
    font-size: 20px;
    position: relative;
    color:$white;
    opacity:0;
    animation-name: opacityIn;
    animation-delay: 1s;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  .header-login img{
    margin-bottom: -80px;
    animation-name: imgLogin;
    animation-delay: .9s;
    animation-duration: .5s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  .header-login.open img{
    margin-bottom: 30px;
    animation-name: imgLoginOpen;
    animation-delay: 0s;
    animation-duration: .3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  .line-login-back{
    position:absolute;
    height:2px;
    top:50%;
    width:100%;
    background-color: #1C478A;
    margin-top: -1px;
  }

  .line-login{
    position:absolute;
    height:2px;
    top:50%;
    background-color: #ffffff;
    margin-top: -1px;
    animation-name: lineLogin;
    animation-duration: .5s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
  }

  .line-login.open{
    width:100%;
    background-color: #ffffff;
    animation-name: lineLoginOpen;
    animation-duration: .3s;
  }

  .line-login.close{
    width:100%;
    background-color: #ffffff;
    animation-name: lineLoginClose;
    animation-duration: .3s;
    animation-fill-mode: forwards;
  }

  .form-login{
    margin-top: -100px;
    position:absolute;
    top: 50%;
    bottom: 0;
    left: 50%;
    width: 300px;
    margin-left: -150px;
    opacity:0;
    text-align: center;
    animation-name: formLogin;
    animation-delay: .9s;
    animation-duration: .5s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  .form-login.open{
    margin-top: 30px;
    animation-name: formLoginOpen;
    animation-delay: 0s;
    animation-duration: .3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

}
