.tiles {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(200px,1fr));
  grid-auto-rows: 0;
}

.tile {
  display: inline-block;
  cursor: pointer;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 5px 9px rgba(36,117,130,.2);
  text-decoration: none;
  border: 1px solid rgba(5,199,179,.2);
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.tileAccount {
  display: inline-block;
  width: calc(33% - 10px);
  cursor: pointer;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 5px 9px rgba(36,117,130,.2);
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
  vertical-align: top;
}

.checkout{
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: rgba(36, 117, 130, 0.2) 0px 5px 9px;
  width: 60%;
  margin-left: 20%;
}

.tileAccount.active {
  border: solid 1px #00e3cc;
  cursor:not-allowed;
}

.tile .tile-wrapper, .tileAccount .tile-wrapper {
  overflow: hidden;
  position: relative;
}

.tile .tile-thumbnail, .tileAccount .tile-thumbnail {
  background-color: #0A1760;
  min-height: 60px;
  border-radius: 8px 8px 0px 0px;
}

.tile .tile-thumbnail img {
  border-radius: 8px 8px 0px 0px;
  display: block;
}

.tile:hover, .tileAccount:hover {
  background-color: #efefef;
}

.tile .tile-content, .tileAccount .tile-content {
  padding: 10px;
  border-radius: 0px 0px 8px 8px;
}

.tile p, .tileAccount p {
  display: inline-block;
  margin: 0;
  color: #0A1760;
}

.tile svg, .tileAccount svg {
  color:#0A1760;
}

.tile .tools {
  margin-top: -25px;
  position: absolute;
  right: 10px;
}

.tile .tools .tool {
  font-size: 14px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  background-color: #fff;
  box-shadow: 0 5px 9px rgba(36,117,130,.2);
  border-radius: 16px;
  text-align: center;
  margin-left: 10px;
}

.tile .infos {
  position: absolute;
  top:10px;
  left: 0;
}

.tile .infos .info {
  font-size: 14px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  background-color: rgba(255,255,255,.7);
  box-shadow: 0 5px 9px rgba(36,117,130,.2);
  border-radius: 16px;
  text-align: center;
  margin-left: 10px;
}
