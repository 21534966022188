.vizualisation-graph, .vizualisation-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.vizualisation-graph .nodes {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: left top;
}

.vizualisation-graph .nodes.blurred{
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='svgMask'><feGaussianBlur stdDeviation='10' /></filter></svg>#svgMask");
  filter: blur(10px);
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
}

.vizualisation-graph .nodes .node {
  position: absolute;
  display: block;
  vertical-align: top;
  pointer-events: none;
  border: none;
}

.vizualisation-graph .nodes .node .selected {
    border: dotted 2px #fff;
  }

.vizualisation-graph .nodes .inactive {
  display: none;
}

.vizualisation-graph .nodes .node .circle {
  vertical-align: top;
  /*-moz-box-shadow: 1px 10px 20px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 1px 10px 20px 0px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 1px 10px 20px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 10px 20px 0px rgba(0, 0, 0, 0.3);
  filter: progid: DXImageTransform.Microsoft.Shadow(color=#c0c0c0, Direction=174, Strength=15);
  border-radius: 0px;*/
  pointer-events: all;
  cursor: pointer;
}

.vizualisation-graph .nodes .node .circle:hover {
  opacity: 0.5
}

.vizualisation-graph .nodes .node .circle:active {
  cursor:grabbing;
}

.vizualisation-graph .nodes .node .selected {
  -moz-box-shadow: 0px 0px 0px 1px #003449 inset;
  -webkit-box-shadow: 0px 0px 0px 1px #003449 inset;
  -o-box-shadow: 0px 0px 0px 1px #003449 inset;
  box-shadow: 0px 0px 0px 1px #003449 inset;
}

.vizualisation-graph .viz-label {
  display: inline-block;
  pointer-events: none;
  padding-bottom: 0;
  padding-top: 0;
  margin-bottom: 0;
  margin-top: 0;
  vertical-align: top;
  pointer-events: none;
  color:$white;
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.vizualisation-graph .categories {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: left top;
}

.vizualisation-graph .categories .node-category {
  position: absolute;
  display: block;
  vertical-align: top;
  pointer-events: none;
  border: none;
}

.vizualisation-graph .categories .node-category .circle {
  vertical-align: top;
  pointer-events: none;
}

.vizualisation-graph .links {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: left top;
}

.vizualisation-graph .links .link {
  display: block;
  stroke-linecap: round;
  opacity: 0.5;
  stroke: $white;
}

.mapview.dark .viz .links .link {
  stroke: $white;
}

.vizualisation-graph .links .inactive {
  display: none;
}

.graticule {
    /*fill: #081148;*/
    fill: none;
    stroke: #777;
    stroke-width: .5px;
    stroke-opacity: .5;
}
