@keyframes headerOpen {
  0%   {top: -70px;}
  100% {top: 0;}
}

header {
  width: 100%;
  top: 0;
  position: fixed;
  display: block;
  z-index: 2;
  background-color: $white;
  /*box-shadow: 0 0 5px 5px $white;*/
}

header.dark {
  background-color: $dark-blue;
  /*box-shadow: 0 0 5px 5px $dark-blue;*/
}

header.open {
  animation-name: headerOpen;
  animation-duration: .5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

header h3 {
  display: inline-block;
  font-size: 30px;
  margin-top: 24px;
}

header.dark h3 {
  color: $white;
}

.navbar {
  width: calc(100% - 40px);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  height: 50px;
}

.navbar-brand {
  height: 50px;
  line-height: 50px;
  display: inline-block;
  vertical-align: top;
  color: $dark-blue;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.navbar-brand:hover {
  color:$tonic-blue;
}

.navbar-brand img {
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  margin-top: 0;
  height: 50px;
}

.navbar-brand span {
  height: 50px;
  line-height: 50px;
  color: $tonic-blue;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
}

.navbar .navbar-center {
  list-style: none;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  margin: 0;
  text-align: left;
  vertical-align: top;
}

.navbar .navbar-right {
  float:right;
  list-style: none;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  margin: 0;
  text-align: left;
  vertical-align: top;
}

.navbar li {
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
}

.navbar li svg {
  margin-right: 10px;
}

.navbar li a {
  color: #C5C5C9;
  font-size: 15px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.navbar li a:hover {
  color: $dark-blue;
}

header.dark .navbar li a:hover {
  color: $white;
}

.navbar li.active,
.navbar li.active:hover {
  /*border-bottom: solid 3px $tonic-blue;*/
}

.navbar li.active a {
  color: $dark-blue;
}

header.dark .navbar li.active a {
  color: $white;
}

@keyframes navbarLineOut {
  0%   {width: 50%;}
  100% {width: 0;}
}

/*.navbar li a:after {
  position: absolute;
  content: '';
  height: 3px;
  bottom: 0px;
  top:52px;
  left: 0;
  right: 0;
  width: 0;
  background: $tonic-blue;
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.navbar li.active a:after {
  width: 50%;
  animation-name: navbarLineOut;
  animation-delay: 0s;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.navbar li a:hover:after {
  width: 50%;
}*/
