.languages {
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;

  .language {
    display: inline-block;
    background-color: #efefef;
    /*border: solid 1px #ac954b;*/
    color: #ac954b;
    padding: 10px;
    /*border-radius: 20px;*/
    margin-left: 1px;
    margin-right: 1px;
    cursor: pointer;
  }

  .language.active {
    background-color: #ac954b;
    color: #fff;
  }

  .hide {
    display: none;
  }
}
