
/*RESPONSIVE*/

@media screen and (max-width: 1023px) {
  .hideOnTablet {
    display: none !important;
  }
  .page-vizualisation-content-documents {
    left: 0 !important;
    right: 0 !important;
    top: 62px !important;
  }
  /*HELP*/
  .help-full-content{
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    .content{
      width: calc(100% - 40px) !important;
      .page-title:after{
        height:0 !important;
      }
    }
  }
  .help-full-illu{
    display:none !important;
  }
  .help .col-container{
    margin-left: 0 !important;
    margin-top: 30px !important;
    width:100% !important;
    .col{
      margin-top: 20px !important;
      .tile{
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 790px) {
  .hideOnMobile {
    display: none !important;
  }
  .showOnMobile {
    display: block !important;
  }
  .navbar-center {
    position: fixed;
    bottom: 10px;
    left:20px;
    right:20px;
    height:70px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 9px rgba(36,117,130,.7);
    li{
      display: flex;
      flex: 1;
      justify-content: center;
      margin-left: 0;
      margin-right: 0;
      a{
        font-size: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: normal;
        svg {
          margin-right: 0;
          font-size: 24px;
          margin-bottom: 5px;
        }
        span {
          line-height: normal;
        }
      }
    }
  }
  .navbar .navbar-right {
    padding-left: 0;
    li {
      margin-left: 5px;
      margin-right: 0px;
      a {
        height: 30px;
        width: 30px;
        line-height: 30px;
        margin-right: 0;
        text-align: center;
        border-radius: 50px;
        background: $white;
        box-shadow: $shadow-neumorphism-light;
        svg {
          margin-right: 0;
        }
      }
    }
  }

  header.dark{
    .navbar li.active a {
      color: #0a1760 !important;
    }
    .navbar .navbar-right {
      li {
        a {
          background: $dark-blue !important;
          box-shadow: $shadow-neumorphism-dark !important;
        }
      }
    }
  }
  .vizualisation-timeline {
    display:none;
  }
  .col-1-3 {
    width:100%;
    margin-right: 0;
    position: relative !important;
  }
  .col-2-3 {
    width:100%;
    margin-left: 0 !important;
    position: relative !important;
  }
  .col-1-4 {
    width:100%;
    margin-left: 0;
    position: relative !important;
  }
  .col.half {
    width:100%;
    margin-left: 0;
  }
  .container {
    margin-left: 0 !important;
  }
  .line .lineContent {
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    width: calc(100% - 54px);
    display: inline-block;
  }
  .line.source p {
    width: calc(100% - 160px);
  }
  .container-add {
    margin-left: 30px !important;
    width: calc(100% - 60px) !important;
  }
  .tabs{
    text-align: center;
    margin-left: 0
  }
  .tab{
    padding-left: 10px !important;
    padding-right: 10px !important
  }
  .page-vizualisation-header{
    z-index: 1 !important;
  }
  .page-vizualisation-modes{
    z-index: 2 !important;
  }
  .page-vizualisation-overlay-documents {
    z-index: 1 !important;
  }
  .page-vizualisation-panel {
    width:calc(100% - 100px)!important;
  }
  .btn-contents{
    right: 10px !important;
    bottom: initial !important;
    top: 28px;
  }
  .page-vizualisation-documents-detail h1{
    font-size: 24px
  }
  .page-vizualisation-tools {
    right: 10px !important;
    left: 80px !important;
    .vizualisation-search {
      max-width: calc(100% - 76px)!important;
    }
    .vizualisation-tool {
      margin-right:0!important;
    }
  }
  .message {
    width: calc(90% - 60px) !important;
    margin-left: -45% !important;
    z-index: 100;
  }
}
