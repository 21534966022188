.vizualisation-timeline{
  position: absolute;
  top: 105px;
  bottom: 70px;
  left: 38px;
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.vizualisation-timeline .timeline-bar-container{
  display: inline-block;
  width: 100px;
  text-align: left;
  margin-top: -1px;
  margin-left: -12px;
  vertical-align: top;
}

.vizualisation-timeline p{
  display: block;
  color: $white;
  margin : 0;
  margin-left: 0;
  text-align: left;
}

.vizualisation-timeline .btn-play{
  display: block;
  margin-top: 16px;
  margin-left: -9px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  color: $white;
  text-align: center;
  border-radius: 16px;
}

.vizualisation-timeline .btn-play svg {
  margin-left: 1px;
  margin-top: 10px;
  vertical-align: top;
}

.vizualisation-timeline .btn-play.decale svg {
  margin-left: 3px;
  margin-top: 10px;
  vertical-align: top;
}

.vizualisation-timeline .timeline-bar-container .timeline-bar{
  display: inline-block;
  margin-left: 0;
  height:6px;
  width:0;
  background-color: $tonic-blue;
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.vizualisation-timeline .timeline-bar-container .timeline-bar:last-child{
  border-radius: 0 3px 3px 0;
}

.vizualisation-timeline .rc-slider-track {
  background:transparent
}

.vizualisation-timeline .rc-slider-handle {
  border-color:$white
}

.vizualisation-timeline .rc-slider-handle:active, .vizualisation-timeline .rc-slider-handle:focus, .vizualisation-timeline .rc-slider-handle:hover {
  border-color:$white;
  box-shadow: 0 0 5px $dark-grey;
}
