@keyframes displayMessage {
  0%   {bottom: 0; opacity:0}
  100% {bottom: 20px; opacity:1}
}

.message {
  font-family: Open Sans,sans-serif;
  font-size: 14px;
  color: $white;
  background-color: $confirm-color;
  margin-bottom: 20px;
  padding: 10px 30px;
  border-radius: 5px;
  text-align: center;
  position: fixed;
  bottom: 0;
  opacity: 1;
  left: 50%;
  margin-left: -230px;
  width: 400px;
  animation-name: displayMessage;
  animation-duration: .5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  a {
    color: $white;
  }
}

.error {
  text-align: center;
  color: $error-color;
  font-family: Open Sans,sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.confirm {
  text-align: center;
  color: $confirm-color;
  font-family: Open Sans,sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.message.error {
  color: $white;
  background-color: $error-color;
}

.message.confirm {
  color: $white;
  background-color: $confirm-color;
}
