.steps{
        position: fixed;
    display: flex;
      align-items: end;
  /*margin-top: 450px;
  margin-left: -15px;*/
  width:max-content;
  margin-left: 115px;
      margin-top: 11px;
  vertical-align: top;
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.steps.data0{
  margin-top: 250px;
}

.steps.data1{
  margin-top: 350px;
}

.steps.data2{
  margin-top: 410px;
}

.steps.data3{
  margin-top: 480px;
}

.steps .step{
  display: flex;
  vertical-align: top;
}

.steps .step p{
  color: $white;
  opacity: 0.6;
  font-size: 12px;
  padding-right: 10px;
  margin: 0 10px 0 0;
  border-bottom: 3px solid #00e3cc;
  display: inline-block;
  vertical-align: top;
  cursor:pointer;
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.steps .step p:hover{
  opacity:0.8
}

.steps .step p .stepNumber{
  font-size: 14px;
  font-weight: 700;
}

.steps .step.active p{
  opacity: 1;
  font-size: 14px;
}

.steps .step.active p .stepNumber{
  font-size: 18px;
}
