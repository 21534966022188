.tabs{
  display: block;
  margin-top: 20px;
  margin-left: 40px;
  /*background-color: #061238;*/

  .tab {
    color: #c5c5c9;
    font-size: 15px;
    text-decoration: none;
    padding-left: 20px;
    padding-right: 20px;
    display: inline-block;
    position:relative;
    text-transform: uppercase;
    cursor: pointer;
  }

  .tab:hover {
    color: #152764;
  }

  .tab.active {
    color: #152764;
  }

  .tab.active:hover {
    color: #152764;
  }

  /*.tab:after {
    position: absolute;
    content: '';
    height: 3px;
    bottom: 0px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 0;
    background: #65E0D9;
    -o-transition: 0.4s;
    -ms-transition: 0.4s;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .tab.active:after {
    position: absolute;
    content: '';
    height: 3px;
    bottom: 0px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 50%;
    background: #65E0D9;
    -o-transition: 0.4s;
    -ms-transition: 0.4s;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .tab:hover:after {
    width: 50%;
    background: #65E0D9;
  }*/

}
