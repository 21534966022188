footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  text-align: center;
  background-color: $white;
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

footer.dark {
  background-color: $dark-blue;
  /*box-shadow: 0 0 5px 5px $dark-blue;*/
}

footer p{
  color: $dark-blue;
  margin: 0;
  margin-top: 26px;
}

footer.dark p{
  color: $white;
  margin: 0;
  margin-top: 26px;
}

footer .copyright svg {
  color: $tonic-blue;
}
@media screen and (max-width: 1280px) {
  .chart-container.half {
    width: calc(100% - 78px);
  }
  .col.half{
    width:100%;
    margin-left: 0;
    margin-right: 0;
    border-left: none;
    padding-left: 0;
    padding-right: 0;
  }
}
