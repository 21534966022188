.help {
  .col{
    border-left: none;
    padding-left: 0;
    padding-right: 0;
  }
  .help-full {
    .help-full-content{
      margin-top: 10px;
      margin-left: 20px;
      background-color: #0A1760;
      border-radius: 8px;
      .help-full-illu{
        display: inline-block;
        vertical-align: top;
        margin-top: -20px;
        margin-left: -20px;
        width: 45%;
      }
      .content{
        width: calc(55% - 20px);
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        display:inline-block;
      }
    }
  }
}
