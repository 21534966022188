@keyframes slideInTop {
  0%   {margin-top: -80px;}
  100% {margin-top: 0;}
}

@keyframes slideInTopAbsolute {
  0%   {top: -50px;}
  100% {top: 28px;}
}

@keyframes slideInLeft {
  0%   {left: -280px; opacity:0}
  100% {left: 20px; opacity:1}
}

@keyframes slideInRight {
  0%   {right: -280px; opacity:0}
  100% {right: 28px; opacity:1}
}

@keyframes slideInComments {
  0%   {transform: translateX(300px)}
  100% {transform: translateX(0)}
}

@keyframes slideInLeftPanel {
  0%   {width: 0; opacity:0}
  100% {width: 210px; opacity:1}
}

@keyframes slideInBottom {
  0%   {bottom: -50px;}
  100% {bottom: 20px;}
}

@keyframes slideInBottomDocuments {
  0% {transform: translateY(100%);}
  100% {transform: translateY(0);}
}

.page-vizualisation {
}

.page-vizualisation-back{
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.page-vizualisation-back.blurred{
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='svgMask'><feGaussianBlur stdDeviation='10' /></filter></svg>#svgMask");
  filter: blur(10px);
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
}

.page-vizualisation-header {
  margin-top: -80px;
  padding-left: 20px;
  padding-top: 20px;
  animation-name: slideInTop;
  /*animation-delay: 0.5s;*/
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  position:absolute;
}

.page-vizualisation-categories {
  position:absolute;
  top: 90px;
  bottom: 90px;
  left: 120px;
  right: 120px;

  display:flex;
  flex-direction: row;

  pointer-events: none;

  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  .category{
    line-height: 16px;
    margin-bottom: 10px;
    background-color: transparent;
    border : none;
    display: flex;
    padding : 0;
    flex : 1;
    text-align: center;

    .category-circle{
      display:inline-block;
      width:4px;
      height:16px;
      vertical-align: top;
      margin-right: 5px;
    }

    a {
      display: inline-block;
      line-height: 36px;
      border-radius: 8px;
      vertical-align: top;
      box-shadow: $shadow-neumorphism-dark;
      margin: 0;
      padding: 0 20px;
      color:#fff;
      overflow: hidden;
      white-space: nowrap;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      max-width: calc(100% - 30px);
      pointer-events: all;
    }

    a:hover {
      color:#fff;
      max-width: none;
    }

    a.disabled {
      background-color:$disabled !important;
      opacity: 0.5;
    }

  }

}

.page-vizualisation-categories.withComments {
  right: 420px;
}

.page-vizualisation-panel {
  position:absolute;
  display:block;
  height: auto;
  max-height: calc(100% - 185px);
  top:75px;
  right:-280px;
  opacity:0;
  width:auto;
  background-color: #fff;
  border-radius:8px;
  overflow-y: auto;
  animation-name: slideInRight;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  box-shadow: $shadow-neumorphism-dark;
  padding: 20px;
  width: 210px;
  z-index: 5;

  .vizualisation-panel-donut{

    .details-donuts{
      text-align: center;
      position: absolute;
      margin-top: -150px;
      width: 210px;

      h3{
        margin-bottom: 0;
        font-weight: bold;
      }
      p{
        margin-top: 0;
      }

    }

  }

}

.panel-category{
  line-height: 16px;
  margin-bottom: 10px;

  .panel-category-circle{
    display:inline-block;
    width:16px;
    height:16px;
    border-radius: 8px;
    vertical-align: top;
    margin-right: 5px;
  }

  p {
    display: inline-block;
    line-height: 16px;
    vertical-align: top;
    margin: 0;
    color:#0A1760;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    width: calc(100% - 24px);
  }

}

.page-vizualisation-tools{
  position:absolute;
  display:block;
  top:-50px;
  right:28px;
  animation-name: slideInTopAbsolute;
  /*animation-delay: 0.5s;*/
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  transition: .4s;

  .vizualisation-search {
    display: inline-block;
    width: 250px;
    margin-right: 10px;
  }

  .vizualisation-tool{
    display: inline-block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 8px;
    vertical-align: top;
    margin-left: 1px;
    margin-right: 1px;

    svg {
      color:#fff;
    }

  }

  .vizualisation-tool.active{
    background: $white;
    box-shadow: $shadow-neumorphism-dark;

    svg {
        color:$darker-blue;
    }

  }

  .vizualisation-tool.btn-comments{
    margin-right: 0;
  }

}

.page-vizualisation-selectedNodes{
  position: absolute;
  right: 20px;
  bottom: 70px;
  text-align: right;

  .panel-category{

    p {
        color:#fff;
    }

  }

}

.page-vizualisation-overlay-documents{
  position:absolute;
  display:block;
  bottom:0;
  right:0;
  top:0;
  left:0;
  overflow: hidden;
  background-color: rgba(10,23,96,0.5);

  .page-vizualisation-content-documents{
    position:absolute;
    display:block;
    bottom:0;
    top:25%;
    transform:translateY(100%);
    left: 180px;
    right: 180px;
    background-color: #ffffff;
    border-radius: 8px 8px 0 0;
    animation-name: slideInBottomDocuments;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;

    .close-documents{
      right: 0;
      margin-top: -50px;
      position: absolute;
      color: $darker-blue;
      background: #fff;
      padding-left: 20px;
      padding-right: 20px;
      height: 36px;
      line-height: 36px;
      /*border: 1px solid #3d4679;*/
      text-align: center;
      border-radius: 8px;
      box-shadow: $shadow-neumorphism-dark;

      svg{
        margin-right: 5px;
      }

    }

    .page-vizualisation-documents-list{
      display: inline-block;
      width: 30%;
      height: 100%;
      vertical-align: top;
      overflow-y: auto;
      word-break: break-word;

      .documents-title {
        padding-left: 20px;
        font-weight: bold;
        color: #0A1760;
        margin-top: 20px;
      }

      .vizualisation-document-item{
        color: #151845;
        display: block;
        border-bottom: solid 1px #dedfec;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-left: 10px;
        position: relative;
      }

      .vizualisation-document-item:hover{
        background-color: #dedfec;
      }

      .vizualisation-document-item.selected {
        font-weight: bold
      }

      .vizualisation-document-item.selected:after {
        position: absolute;
        content: "";
        height: 100%;
        bottom: 0;
        top: 0;
        left: -20px;
        right: 0;
        width: 8px;
        border-radius: 0 4px 4px 0;
        background: #00e3cc;
      }

    }

    .page-vizualisation-documents-detail{
      display: inline-block;
      width: calc(70% - 40px);
      height: 100%;
      vertical-align: top;
      padding-left: 20px;
      padding-right: 20px;
      min-height: 100%;
      overflow-y: auto;
      box-shadow: 0px 2px 32px rgba(9, 21, 91, 0.2);
      word-break: break-word;
    }

  }

}

.page-vizualisation-modes{
  position: absolute;
  display: block;
  bottom: -50px;
  left: 50%;
  width: 360px;
  margin-left: -150px;
  text-align: center;
  animation-name: slideInBottom;
  animation-delay: .5s;
  animation-duration: .5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  transition: .4s;

  .vizualisation-mode{
    color: #fff;
    height: 36px;
    line-height: 36px;
    display: inline-block;
    position: relative;
    width: 100px;
    padding-left: 10px;
    padding-right: 10px;
    /*background: #081148;*/
    svg{
        margin-right: 5px
    }
  }

  .vizualisation-mode:first-child{
    border-radius: 8px 0 0 8px;
  }

  .vizualisation-mode:last-child{
    border-radius: 0 8px 8px 0;
  }

  .vizualisation-mode.active {
      color: $darker-blue;
    background: $white;
    border-radius: 8px;
    box-shadow: $shadow-neumorphism-dark;
  }

  /*.vizualisation-mode.active:after {
    position: absolute;
    content: "";
    height: 8px;
    bottom: 0;
    top: 62px;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 4px 4px 0 0;
    background: #00e3cc;
}*/

}

.btn-contents{
  position:absolute;
  display:block;
  bottom:-50px;
  right:28px;
  animation-name: slideInBottom;
  /*animation-delay: 0.5s;*/
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  transition: .4s;

  .btn-documents{
    color: $darker-blue;
    display:inline-block;
    background: #fff;
    padding-left: 20px;
    padding-right: 20px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 8px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: $shadow-neumorphism-dark;
  }

}


.comments {
  position: absolute;
  right: 0;
  width: 300px;
  transform: translateX(300px);
  bottom: 0;
  top: 0;
  background: #030e34;
  animation-name: slideInComments;
  /*animation-delay: 0.5s;*/
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  .comments-list{
    display: block;
    width: calc(100% - 40px);
    height: 100%;
    vertical-align: top;
    overflow-y: auto;
    padding-left: 20px;
    word-break: break-word;

    .comment-item{
      color: $white;
      display: block;
      border-bottom: solid 1px $disabled;
      padding-bottom: 10px;
      padding-top: 10px;
      padding-left: 10px;
      position: relative;
    }

    .comment-item:hover{
      background-color: $dark-blue;
    }

    .comment-item.selected {
      font-weight: bold
    }

    .comment-item span {
      display: inline-block;
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: calc(100% - 50px);
    }

    .comment-item.selected span {
      font-weight: bold;
      overflow: inherit;
      white-space: inherit;
      text-overflow: inherit;
      width: 100%;
    }

    .comment-item.selected:after {
      position: absolute;
      content: "";
      height: 100%;
      bottom: 0;
      top: 0;
      left: -20px;
      right: 0;
      width: 8px;
      border-radius: 0 4px 4px 0;
      background: $tonic-blue;
    }
    .comment-item svg {
      margin-left: 11px;
      margin-right: 10px;
      color:$white;
    }

    .comment-item .tools {
      display: inline-block;
      float: right;
      margin-right: 10px;
    }

    .comment-item .tools a {
      font-size: 16px;
    }
  }

}
