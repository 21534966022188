.line {
  height: 50px;
  display: inline-block;
  width: 100%;
  line-height: 50px;
  border: 1px solid rgba(5,199,179,0.2);
  cursor: pointer;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 5px 9px rgba(36,117,130,.2);
  margin-top: 10px;
}

.line.disabled{
  background: $disabled;
}

.line.dark {
  background: #071144;
  border: none;
  box-shadow: none;
}

.line a {
  text-decoration: none;
}

.line:hover {
  background-color: #efefef;
}

.line.dark:hover {
  background-color: #091350;
}

.line p {
  display: inline-block;
  margin: 0;
}

.line.source p {
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: calc(100% - 460px);
  vertical-align: top;
}

.line.dark p {
  color:#fff;
}

.line svg {
  margin-right: 10px;
  color:#0A1760;
}

.line .picto {
  display: inline-block;
}

.line svg:first-child {
  margin-left: 20px;
}

.line .picto svg:first-child {
  margin-left: 0;
}

.line.dark svg {
  color:#fff;
}

.line .tools {
  display: inline-block;
  float: right;
  margin-right: 10px;
}

.line .tools a {
  font-size: 16px;
}

.line.selected {
  font-weight: bold
}

.list-item{
  color: #151845;
  display: block;
  border-bottom: solid 1px #dedfec;
  /*height: 40px;
  line-height: 40px;*/
  padding-left: 10px;
  position: relative;
  text-align: left;
}

.list-item:hover{
  background-color: #dedfec;
}

.list-item.selected:after {
  position: absolute;
  content: "";
  height: 100%;
  bottom: 0;
  top: 0;
  left: -20px;
  right: 0;
  width: 8px;
  border-radius: 0 4px 4px 0;
  background: #00e3cc;
}

.list-item a {
  text-decoration: none;
}

.list-item p {
  display: inline-block;
  margin: 0;
}

.list-item.selected p {
  font-weight: bold
}

.list-item svg {
  margin-left: 11px;
  margin-right: 10px;
  color:#0A1760;
}

.list-item .tools {
  display: inline-block;
  float: right;
  margin-right: 10px;
}

.list-item .tools a {
  font-size: 16px;
}
